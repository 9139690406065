.menubar {
  position: fixed;
  z-index: 30;
  bottom: 1.5rem;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  width: 15rem;
  height: 2.5rem;
  background-color: #2a546c;
  color: #f4f4e3;

  border-radius: 2rem;
  border: 2px solid #fff;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.menubar a,
.menubar span {
  color: #f4f4e3;
}

.menubar a {
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}
.menubar a:nth-child(1) {
  background-image: url('../images/icon-presentation.png');
}
.menubar a:nth-child(2) {
  background-image: url('../images/icon-parcours.png');
}
.menubar a:nth-child(3) {
  background-image: url('../images/icon-portefolio.png');
}
.menubar a:nth-child(4) {
  background-image: url('../images/icon-contactez-moi.png');
}
