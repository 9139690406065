.scotch {
  position: relative;
  margin-right: 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-repeat: no-repeat;
  background-size: 100% 100%;

  color: var(--color-pm-2);
  font-family: var(--font-julius);
  font-weight: 600;
  font-style: normal;
}

.scotch.desktop {
  width: 400px;
  height: 80px;

  font-size: 2rem;
  background-image: url('../images/scotch-long.png');
}

.scotch.mobile {
  width: 300px;
  height: 50px;
  margin: 1rem;
  font-size: 1.35rem;
  background-image: url('../images/scotch-short.png');
}

.scotch.mobile.full {
  width: calc(100% - 1rem);
  margin: 0rem 0 1.5rem 0;
}

/* convertisseur path/polygon SVG 
https://www.plantcss.com/css-clip-path-generator?q=%22GGRcNXZdHWEDNX59NWV6NWMNNXZ6GWJfNGZ7MqNtNGRfMWV4MVddRWV6OqENMX59MWF7RWRaGWZtNqtfMFd9OqJ9MGBtNaINNXZaLH58GWJfLWJ4MaFtMWFfMFddRWFcOqENMX59MWF7RWF6GWZtMqRfMFd9OqJ4Max7RWB5OqZ7MWENNXZ6MVd8RWtdOqF4LWMDLWQRNqVdEqtdOqF4LWMNNqV8RWx9GWRcNX55LGNtMqtfMGtdM9dbNGFtMqNfMFdbNGZfMGF9RWF5GWRcMHZ8Nr58GWRcNX58MGJtMWwNNqV8RWJcOqENNqVdOqV8RWN8OqENNqV8RWR4OqENNqVdOqF8MXZbMVdbNGFtNGtfMFdbNGZfMGZbRWVbOqpaMGENNqV8RWwNNqVdRWZfMWZcMaZaEqYz%22
*/
