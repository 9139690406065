section.exposed {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

section.notExposed {
  background-color: green;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
