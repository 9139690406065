@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Julius+Sans+One&family=Overpass:ital,wght@0,100..900;1,100..900&family=Reenie+Beanie&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sofia+Sans+Semi+Condensed:ital,wght@0,1..1000;1,1..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.bubble-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}

.bubble {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin: 1rem 2rem 1rem 2rem; */
}

.bubble .langage {
  /* background-color: aqua; */
  background-repeat: no-repeat;
  background-position: center;
}

.bubble .panel-text {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: rgba(255, 255, 255, 0.95);
  top: 2rem;
  left: 2rem;
  width: calc(100vw - 4rem);
  min-height: 30rem;
  height: auto;
  max-height: calc(100vh - 4rem);
  padding-bottom: 1rem;

  border: 1px solid #cecece;
  border-radius: 0.25rem;
  overflow: hidden;

  opacity: 0; /* Commence invisible */
  transition: opacity 0.3s ease;
  z-index: 10;
}

/* BARRE DE TITRE */

.bubble .panel-text .top-bar {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 2rem;
  background-color: #dedede;
  border-bottom: 1px solid #cecece;
}

.bubble .panel-text div.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;

  font-family: 'Sofia Sans Semi Condensed', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 1.1rem;

  color: rgb(9, 101, 138);
  width: 100%;
  height: 100%;
}

.bubble .close-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 2rem;
  height: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  background-image: url('../images/icon-close.png');
}

/* FERMETURE et OUVERTURE du PANEL */
.bubble .panel-text.closed {
  opacity: 0; /* Masque l'élément lorsque fermé */
  pointer-events: none; /* Ignore les clics lorsque fermé */
}

.bubble .panel-text.open {
  opacity: 1; /* Affiche l'élément lorsque ouvert */
  pointer-events: auto; /* Permet les clics lorsque ouvert */
}

/* LE CONTENU PRINCIPAL */
.content-image {
  width: 10rem;
  height: 10rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  font-family: 'Josefin Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  line-height: 1.3;
  text-align: start;

  width: calc(100% - 4rem);
  height: 100%;
  padding: 2rem;
  padding-top: 0;
  line-height: 1.5;
}

.bubble .content div {
  margin-top: 0.5rem;
}

/* LES IMAGES DES BUBBLES */
.langage {
  width: 10rem;
  height: 10rem;
  background-size: contain;
}

.langage.vb {
  background-image: url('../images/parcours/vb.png');
}
.langage.java {
  width: 7rem;
  height: 7rem;
  background-image: url('../images/parcours/java.png');
}
.langage.html {
  background-image: url('../images/parcours/html.png');
}
.langage.jquery {
  background-image: url('../images/parcours/jquery.png');
}
.langage.php {
  width: 5rem;
  height: 5rem;
  background-image: url('../images/parcours/php.png');
}
.langage.cms {
  width: 8rem;
  height: 8rem;
  background-size: contain;
  background-image: url('../images/parcours/cms.png');
}
.langage.javascript {
  width: 12rem;
  height: 12rem;
  background-image: url('../images/parcours/javascript.png');
}
.langage.nodejs {
  width: 10rem;
  height: 10rem;
  background-image: url('../images/parcours/nodejs.png');
}

.langage.react {
  width: 9rem;
  height: 19em;
  background-image: url('../images/parcours/react.png');
}
.langage.reactnative {
  width: 5rem;
  height: 5rem;
  background-image: url('../images/parcours/reactnative.png');
}

/* --------------------------------------- */
.content-image.vb {
  background-image: url('../images/parcours/vb.png');
}
.content-image.java {
  background-image: url('../images/parcours/java.png');
}
.content-image.html {
  background-image: url('../images/parcours/html.png');
}
.content-image.jquery {
  background-image: url('../images/parcours/jquery.png');
}
.content-image.php {
  background-image: url('../images/parcours/php.png');
}
.content-image.cms {
  background-image: url('../images/parcours/cms.png');
}

.content-image.javascript {
  background-image: url('../images/parcours/javascript.png');
}
.content-image.nodejs {
  background-image: url('../images/parcours/nodejs.png');
}

.content-image.react {
  background-image: url('../images/parcours/react.png');
}
.content-image.reactnative {
  background-image: url('../images/parcours/reactnative.png');
}

/* APERCU DU CONTENU */
.view {
  width: 100%;
  height: 10rem;
  border-radius: 6px;
  margin: 0.5rem;
  border: 1px solid #cecece;
  background-size: cover;
  background-repeat: no-repeat;
}

.view.jquery {
  background-image: url('https://media.dev.to/cdn-cgi/image/width=1000,height=420,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fi%2Fsbn71yujt0f3ug5k1mvs.jpeg');
}
.view.html {
  height: 11rem;
  background-image: url('https://www.ionos.fr/digitalguide/fileadmin/DigitalGuide/Teaser/html-tagst.jpg');
}
.view.cms {
  height: 8rem;
  background-image: url('https://www.raphaelle-lecot.fr/images/cms.png');
}
.view.php {
  background-color: #fff;
  background-size: contain;
  background-image: url('https://www.simplilearn.com/ice9/free_resources_article_thumb/php_page_workflow_5.png');
}
